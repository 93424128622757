<template>
  <div
    ref="widgetWrapper"
    class="fixed widgetPos flex content-end transition-all duration-300 z-[531] print:hidden z-5"
    :class="[
      isMobile ? 'top-md h-[calc(100%-60px)]' : 'bottom-xl',
      showFlyout ? '!right-0' : '',
    ]"
  >
    <div class="flex flex-col mt-auto bottom-md gap-y-[1px] mb-xl w-0">
      <transition name="fade">
        <button
          v-show="y > 300"
          id="pagetop"
          ref="scrollToTopButton"
          title="Scroll up"
          class="rounded-l-xl w-[3.125rem] h-[3.125rem] bg-minilu-btn-base flex justify-center items-center default right-[50px] relative ml-auto"
          :class="{
            'w-[9rem] right-[9rem] text-white': scrollToTopButtonIsHovered,
          }"
          @click="toTop()"
        >
          <FaIcon icon-class="fal fa-angles-up" />
          <div
            v-show="scrollToTopButtonIsHovered"
            class="text-lg font-light ml-xs whitespace-nowrap font-pero"
          >
            {{ t('globalWidget.toTopLabel') }}
          </div>
        </button>
      </transition>
      <button
        ref="contactButton"
        title="Contact Information"
        class="rounded-l-xl w-[3.125rem] h-[3.125rem] bg-minilu-btn-base flex justify-center items-center default right-[50px] relative ml-auto"
        :class="{ 'w-[8rem] right-[8rem] text-white': showContact }"
        @click="openContactView(EContactView.CONTACT)"
      >
        <FaIcon icon-class="far fa-phone-plus" />
        <div
          v-show="showContact"
          class="text-lg font-light ml-xs whitespace-nowrap font-pero"
        >
          {{ t('globalWidget.contactLabel') }}
        </div>
      </button>
      <button
        v-if="areaStore.getActiveArea?.id.toString() === '134662'"
        id="pagetop"
        ref="errorButton"
        title="Contact Information"
        class="rounded-l-xl w-[3.125rem] h-[3.125rem] bg-sign-yellow flex justify-center items-center default right-[50px] relative ml-auto"
        :class="{ 'w-[10rem] right-[10rem] text-white': showError }"
        @click="openContactView(EContactView.ERROR)"
      >
        <FaIcon icon-class="fal fa-triangle-exclamation" />
        <div
          v-show="showError"
          class="text-lg font-light ml-xs whitespace-nowrap font-pero"
        >
          {{ t('globalWidget.errorLabel') }}
        </div>
      </button>
      <button
        v-if="
          assignedBanners.length > 0 &&
          assignedBanners[0].banner.typeHandle ===
            BannerTypeHandle.WIDGET_NOTIFICATION
        "
        id="pagetop"
        ref="widgetButton"
        :title="
          (assignedBanners[0].banner as WidgetNotificationBannerData).headline
        "
        class="rounded-l-xl w-[3.125rem] h-[3.125rem] bg-status-danger-base flex justify-center items-center default right-[50px] relative ml-auto"
        :class="{ '!w-[8rem] !right-[8rem] text-white': showWidget }"
        @click="openContactView(EContactView.BANNER)"
      >
        <FaIcon icon-class="far fa-bell-on" class="shrink-0" />
        <div
          v-show="showWidget"
          class="overflow-hidden ml-xs whitespace-nowrap text-ellipsis w-[calc(100%-60px)] text-lg font-light font-pero"
        >
          {{ t('globalWidgets.notificationLabel') }}
        </div>
      </button>
    </div>
    <div class="flex flex-col overflow-hidden widgetWidth">
      <ContactDialog
        :dialog="activeContactView"
        @close="closeContactView()"
        @handle-closing="handleClosingWidgetFromContactDialog"
      />
    </div>

    <div
      v-show="showFlyout && isMobile"
      class="fixed inset-0 overflow-hidden -z-1 minilu-toggleNav__overlay"
      @click="closeContactView()"
    ></div>
  </div>
</template>

<script setup lang="ts">
/** Imports */
import { EContactView } from '@/@types/contactview-type';
import {
  useWindowScroll,
  useElementHover,
  onClickOutside,
  useTimeout,
  promiseTimeout,
} from '@vueuse/core';
import { useAreaStore } from '@/stores/useArea';
import ContactDialog from '~~/src/components/components/widgets/minilu/contact-dialog.vue';
import { mq_breakpointIsMobile } from '@/injectionSymbols';
import FaIcon from '@/components/fa-icon.vue';
import { BannerPositions } from '~/@types/bannerPositions';
import { useAssignedBanners } from '~/composables/banner/useAssignedBanners';
import { BannerTypeHandle } from '~/components/banner/types';
import type { WidgetNotificationBannerData } from '~/components/banner/components/banners/widgetNotification/type';

const areaStore = useAreaStore();

const { t } = useTrans();

const isMobile = inject(mq_breakpointIsMobile);

/** Component data */
const { y } = useWindowScroll();
const widgetWrapper = ref();
const scrollToTopButton = ref();
const contactButton = ref();
const errorButton = ref();
const widgetButton = ref();
const showFlyout = ref(false);
const activeContactView = ref();

const { assignedBanners } = await useAssignedBanners(
  BannerPositions.WIDGET_NOTIFICATION,
);

watch(
  () => showFlyout.value && isMobile.value,
  (newVal) => {
    usePreventBodyScroll(newVal);
  },
);

const emit = defineEmits<{
  (e: 'handleClosing'): void;
}>();

function handleClosingWidgetFromContactDialog(): void {
  emit('handleClosing');
  closeContactView();
}

const scrollToTopButtonIsHovered = useElementHover(scrollToTopButton);
const contactButtonIsHovered = useElementHover(contactButton);
const errorButtonIsHovered = useElementHover(errorButton);
const widgetButtonIsHovered = useElementHover(widgetButton);

const { ready } = useTimeout(5000, { controls: true });
const showContact = computed(() => {
  return (contactButtonIsHovered.value && !isMobile.value) || !ready.value;
});
const showError = computed(() => {
  return (errorButtonIsHovered.value && !isMobile.value) || !ready.value;
});
const showWidget = computed(() => {
  return (widgetButtonIsHovered.value && !isMobile.value) || !ready.value;
});

/** Methods */
function openContactView(contactView: EContactView) {
  activeContactView.value = contactView;
  showFlyout.value = true;
}

function closeContactView() {
  showFlyout.value = false;
  window.setTimeout(() => {
    activeContactView.value = null;
  }, 550);
}

async function toTop(): Promise<void> {
  if (import.meta.client) {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    await promiseTimeout(1200);
    scrollToTopButtonIsHovered.value = false;
  }
}

onClickOutside(widgetWrapper, () => closeContactView());
</script>

<style scoped>
.default {
  transition: left 0.25s width 0.25s ease-out;
  transition-duration: 0.25s;
  color: #ffffff;
}
.minilu-toggleNav__overlay {
  background: transparent linear-gradient(180deg, #000000 0%, #346d7300 100%) 0%
    0%;
  opacity: 0.2;
}

.widgetWidth {
  width: 390px;
}
.widgetPos {
  right: -390px;
}

@media screen and (max-width: 600px) {
  .widgetWidth {
    width: calc(100vw - 50px);
  }
  .widgetPos {
    right: calc(-100vw + 50px);
  }
}
</style>
