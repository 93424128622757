<template>
  <!-- @info messages will be implemented in a later release
  <Hotlink icon="far fa-bell" :notifications="3" ident="notifications">
    <template #default>{{ t('header.links.messages') }}</template>
    <template #dropdown>
      {{ 'messages' }}
    </template>
  </Hotlink>
  -->
  <Hotlink
    icon="far fa-user"
    :is-active-page="$route.path.toLowerCase() === '/account'"
    :notifications="null"
    ident="user"
    to="/account"
    testid="headerLinkAccount"
    :show-overlay="false"
    :active-mobile-hover-ident="currentMobileHover"
    @set-active-hover="(val) => toggleCurrentHoverIdent(val)"
  >
    <template #default="{ isActive }">
      <StyledMy
        :key="userType.currCustomerTypeId"
        :text="t(userType.linkText)"
        :style-type="
          getMyNavStyle($route.path.toLowerCase() === '/account', isActive)
        "
    /></template>

    <template #dropdown="{ closeCb }">
      <MyPraxis @close="closeCb" />
    </template>
  </Hotlink>
  <Hotlink
    :is-active-page="$route.path.toLowerCase() === '/productlists'"
    icon="far fa-heart"
    :notifications="null"
    ident="products"
    :show-overlay="false"
    to="/productlists"
    testid="headerLinkProducts"
    :active-mobile-hover-ident="currentMobileHover"
    @set-active-hover="(val) => toggleCurrentHoverIdent(val)"
  >
    <template #default="{ isActive }"
      ><StyledMy
        :style-type="
          getMyNavStyle($route.path.toLowerCase() === '/productlists', isActive)
        "
        :text="t('header.links.products')"
    /></template>
    <template #dropdown="{ closeCb }"><MyProducts @close="closeCb" /></template>
  </Hotlink>
  <Hotlink
    to="/checkout/basket"
    :is-active-page="$route.path.toLowerCase() === '/checkout/basket'"
    icon="far fa-cart-shopping"
    :notifications="cart.cartItemAmount || null"
    ident="cart"
    :width="HotlinkWidth.wide"
    :height="HotlinkHeight.full"
    :notification-key="UseStateKeys.HOTLINK_BASKET_NOTIFICATION"
    :mobile-notification-name="t('header.links.cart')"
    :show-overlay="false"
    :touch-link-only="true"
    testid="headerCartLink"
    :active-mobile-hover-ident="currentMobileHover"
    @set-active-hover="(val) => toggleCurrentHoverIdent(val)"
  >
    <template #default> {{ t('header.links.cart') }}</template>
    <template #dropdown="{ closeCb }"><MyCart @close="closeCb" /></template>
  </Hotlink>
</template>
<script setup lang="ts">
import StyledMy from '@/components/layout/header/components/styledMy/styledMy.vue';
import Hotlink from '@/components/layout/header/components/hotlink/hotlink.vue';
import MyPraxis from '@/components/layout/header/components/hotlink/components/myPraxis.vue';
import MyProducts from '@/components/layout/header/components/hotlink/components/productlists.vue';
import MyCart from '@/components/layout/header/components/hotlink/components/myCart/myCart.vue';
import { UseStateKeys } from '@/useStateKeys';
import { useCartStore } from '~/stores/useCart';

import {
  HotlinkWidth,
  HotlinkHeight,
} from '@/components/layout/header/components/hotlink/useHotlink';
import { EStyleType as EStyleTypeStyledMy } from '@/components/layout/header/components/styledMy/useStyledMy';
import { useDashboardDynamicLink } from '@/composables/useDashboardDynamicLink';
const userType = useDashboardDynamicLink();

const { t } = useTrans();

const cart = useCartStore();

function getMyNavStyle(isActiveRoute: boolean, isHovered: boolean) {
  switch (true) {
    case !isActiveRoute && !isHovered:
      return EStyleTypeStyledMy.default;
    case isActiveRoute && !isHovered:
      return EStyleTypeStyledMy.light;
    default:
      return EStyleTypeStyledMy.lightAlt;
  }
}

const currentMobileHover = ref('');

function toggleCurrentHoverIdent(value: string) {
  value ? (currentMobileHover.value = value) : '';
}
</script>
