<template>
  <div
    data-testid="nav"
    class="relative w-full h-[60px] bg-minilu-base-lightest print:hidden font-pero text-lg"
    @mouseleave="hoverMenuUnhover()"
  >
    <div
      class="relative w-full h-[60px] flex flex-row flex-nowrap pl-sm max-w-screen-2xl mx-auto pr-sm md:pr-0"
    >
      <div
        class="flex flex-row items-center w-full md:w-[120px] xl:w-[180px] gap-md md:gap-0 overflow-hidden"
      >
        <div
          class="flex flex-row items-center w-auto font-bold transition-all ease-linear cursor-pointer md:ml-sm xl:ml-sm flex-nowrap"
          :class="{ '-ml-[100px]': search }"
          data-testid="menuToggleButton"
          @click="toggleMenuClick()"
        >
          <FaIcon icon-class="fas fa-bars" classes="mr-2" />
          {{ t('header.menu') }}
        </div>

        <SearchbarMobile class="grow" />
      </div>

      <div
        v-if="!isMobileScreen"
        :key="activeNavStructureComputed.activeItem?.id"
        class="relative flex flex-row overflow-hidden -ml-md"
      >
        <HorizontalScroll
          :key="activeNavStructureComputed.activeItem.id"
          arrow-class="bg-minilu-base"
        >
          <template v-for="link in mainNavLinks" :key="link.navItem.id">
            <NuxtLink
              v-if="!link.noLink"
              data-testid="navitem"
              :to="link.navItem.navUri"
              :target="link.navItem.openInNewWindow ? '_blank' : '_self'"
              :class="[
                link.cssClass,
                link.navItem === state.hoverNav.hoveredItem
                  ? '!bg-white !text-minilu-font after:!border-white'
                  : '',
                {
                  'item--active':
                    activeNavStructureComputed.activeNavItem?.id ===
                      link.navItem?.id ||
                    activeNavStructureFromStore.activeItem?.id ===
                      link.navItem?.id,
                },
              ]"
              class="snap-end"
              @mouseenter="mouseEnter($event, link)"
              @mouseleave="mouseLeave"
              @click="navItemUnhover"
            >
              <span @mouseover.stop>
                <StyledMy
                  :key="userType.currCustomerTypeId"
                  :text="
                    link.navItem.id == MyAccountIds.MY_ACCOUNT
                      ? t(userType.linkText)
                      : t(link.navItem.title)
                  "
                  :font-size-px="20"
                  :style-type="
                    getMyNavStyle(
                      activeNavStructureComputed.activeNavItem?.id ===
                        link.navItem?.id ||
                        activeNavStructureFromStore.activeItem?.id ===
                          link.navItem?.id
                        ? EMainNavLinkStyle.ACTIVE
                        : link.cssClass,
                      link.navItem === state.hoverNav.hoveredItem,
                    )
                  "
                />
              </span>
              <span v-if="linkShowsDropdownIcon(link.cssClass)" @mouseover.stop>
                <span
                  v-show="link.navItem.id === state.hoverNav.hoveredItem?.id"
                >
                  <FaIcon
                    icon-class="far fa-chevron-up"
                    classes="text-sm ml-xs"
                  />
                </span>
                <span
                  v-show="link.navItem.id !== state.hoverNav.hoveredItem?.id"
                >
                  <FaIcon
                    icon-class="far fa-chevron-down"
                    classes="text-sm ml-xs"
                  />
                </span>
              </span>
            </NuxtLink>
            <div
              v-else
              :class="[
                link.cssClass,
                link.navItem === state.hoverNav.hoveredItem
                  ? '!bg-white !text-minilu-font after:!border-white'
                  : '',
                'snap-end',
                'js-nav-item',
              ]"
              @click="touchClick($event, link)"
            >
              <span>{{ t(link.navItem.title) }}</span>
              <span v-if="linkShowsDropdownIcon(link.cssClass)">
                <span
                  v-show="link.navItem.id === state.hoverNav.hoveredItem?.id"
                >
                  <FaIcon
                    icon-class="far fa-chevron-up"
                    classes="text-sm ml-xs"
                  />
                </span>
                <span
                  v-show="link.navItem.id !== state.hoverNav.hoveredItem?.id"
                >
                  <FaIcon
                    icon-class="far fa-chevron-down"
                    classes="text-sm ml-xs"
                  />
                </span>
              </span>
            </div>
          </template>
        </HorizontalScroll>
      </div>
    </div>
    <ToggleNavV2
      :show="state.toggleNav.show"
      @close="toggleMenuClick()"
    ></ToggleNavV2>
    <HoverNavV2
      :state="state.hoverNav"
      @hover="hoverMenuHover()"
      @link-click="hoverMenuUnhover()"
      @close="hoverMenuUnhover()"
    ></HoverNavV2>
  </div>
</template>

<script setup lang="ts">
import { useNav } from '@/stores/useNav';
import { storeToRefs } from 'pinia';

import ToggleNavV2 from './components/toggle-nav.vue';
import HoverNavV2 from './components/hover-nav.vue';
import HorizontalScroll from '@/components/helper/horizontal-scroll.vue';
import SearchbarMobile from '@/components/layout/header/components/searchbar/searchbarMobile.vue';
import StyledMy from '@/components/layout/header/components/styledMy/styledMy.vue';
import { mq_breakpointIsMobile } from '@/injectionSymbols';
import useMainMavState from '../composables/useMainNavState';
import FaIcon from '@/components/fa-icon.vue';
import { MyAccountIds } from '~/@types/navigationIds';
import { useDashboardDynamicLink } from '@/composables/useDashboardDynamicLink';
const userType = useDashboardDynamicLink();

const {
  state,
  touchClick,
  mainNavLinks,
  mouseEnter,
  mouseLeave,
  navItemUnhover,
  linkShowsDropdownIcon,
  toggleMenuClick,
  hoverMenuHover,
  hoverMenuUnhover,
  getMyNavStyle,
  activeNavStructureComputed,
} = useMainMavState();
const { t } = useTrans();
const isMobileScreen = inject(mq_breakpointIsMobile);

enum EMainNavLinkStyle {
  ACTIVE = 'item item--active',
}

const navStore = useNav();
const { mainNav_activeStructure: activeNavStructureFromStore } =
  storeToRefs(navStore);

const search = ref('');
</script>

<style scoped lang="postcss">
.item {
  @apply h-[60px] px-[20px] text-lg relative hover:bg-white hover:text-minilu-font flex flex-row items-center flex-nowrap whitespace-nowrap;
  &--parent {
    @apply breadcrumb-arrow pr-[20px];
    & + * {
      @apply pl-[30px];
    }
    &:hover {
      @apply breadcrumb-arrow--hover;
    }
  }
  &--activeParent {
    @apply breadcrumb-arrow breadcrumb-arrow--active bg-minilu-base text-white;
    &:hover {
      @apply breadcrumb-arrow--hover;
    }
    & + * {
      @apply pl-md;
    }
  }
  &--active {
    @apply bg-minilu-base text-white;
  }
  &--hidden {
    @apply hidden;
  }
}

.breadcrumb-arrow {
  &::after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    margin-left: 30px;
    bottom: -38px;
    right: -32px;
    box-sizing: border-box;
    z-index: 5;
    border: 17px solid black;
    border-radius: 5px;
    border-color: transparent transparent var(--minilu-base-lightest)
      var(--minilu-base-lightest);
    transform-origin: 0 0;
    transform: rotate(225deg) skew(25deg, 25deg);
    box-shadow: -4px 5px 3px 0 rgb(0 0 0 / 13%);
  }
  &--active {
    &::after {
      border-color: transparent transparent var(--minilu-base)
        var(--minilu-base) !important;
    }
  }
  &--hover {
    &::after {
      border-color: transparent transparent white white !important;
    }
  }
}
</style>
