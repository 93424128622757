<template>
  <div
    class="relative w-full mb-sm"
    :class="[isMobile ? 'h-full' : 'h-[85vh]']"
  >
    <transition-group name="slide">
      <div
        v-for="(view, index) in views"
        v-show="activeView.toString() == index"
        :key="view.title"
        class="absolute min-h-[300px] bottom-0 left-0 w-full flex flex-col max-h-full overflow-x-hidden shadow-md rounded-l-xl mb-xs"
      >
        <div
          v-if="view.title"
          :class="[
            'w-full text-white bg-vdv-btn-base py-sm px-sm md:px-md font-pero text-[22px] leading-8 font-normal rounded-tl-xl flex justify-between items-center whitespace-nowrap ',
            {
              [view.bgColor]: view.bgColor,
              'sticky left-0 right-0 top-0': isMobile,
            },
          ]"
        >
          <span
            class="text-ellipsis overflow-hidden w-auto md:w-[300px] shrink-0"
            >{{ t('globalWidgets.notificationLabel') }}</span
          >
          <div class="w-full leading-8 text-right text-white closeBtn">
            <FaIcon
              icon-class="far fa-xmark fa-2xs"
              class="cursor-pointer h-4 w-[9px]"
              @click="closeContactView"
            />
          </div>
        </div>

        <slot :name="`view_${view.id}`" :goto="goto" :next="next" :prev="prev">
          <pre>No content found</pre>
        </slot>
      </div>
    </transition-group>
  </div>
</template>

<script setup lang="ts">
/** Imports */
import { mq_breakpointIsMobile } from '@/injectionSymbols';
import FaIcon from '@/components/fa-icon.vue';

/** Props */
const props = defineProps({
  views: {
    //Resolves to :property-foo=""
    type: Object,
    required: true,
  },
});

const { t } = useTrans();

const isMobile = inject(mq_breakpointIsMobile);

const forward = ref(true);

const activeView = ref(0);

const emit = defineEmits<{
  (e: 'handleClosingFromInline'): void;
}>();

function closeContactView(): void {
  emit('handleClosingFromInline');
  forward.value = false;
  window.setTimeout(() => {
    activeView.value = 99;
  }, 550);
}

function next() {
  forward.value = true;
  if (props.views.length == activeView.value + 1) {
    return;
  }
  activeView.value++;
}

function prev() {
  forward.value = false;
  if (activeView.value == 0) {
    return;
  }
  activeView.value--;
}

function goto(viewId: string) {
  forward.value = true;
  activeView.value = parseInt(viewId);
}
</script>

<style scoped>
.dialog-slider {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: hidden;
}

.slide-leave-active,
.slide-enter-active {
  transition: 0.3s;
}

.slide-enter-active {
  left: 100%;
  right: auto;
}

.slide-enter-to {
  transform: translate(-100%, 0);
}
.slide-leave-to {
  transform: translate(-100%, 0);
}

.slide-reverse-leave-active,
.slide-reverse-enter-active {
  transition: 0.3s;
}

.slide-reverse-enter-active {
  left: -100%;
  right: auto;
}
.slide-reverse-enter-to {
  transform: translate(100%, 0);
}
.slide-reverse-leave-to {
  transform: translate(100%, 0);
}
</style>
