<template>
  <div class="border-bot">
    <LoginStatus
      testid="toggleNaviLoginButton"
      :full-width="true"
      @click="$emit('close')"
    >
      <div
        v-if="userContext.initialized"
        class="flex flex-row px-md gap-sm py-md"
      >
        <UserIcon :size="50" />
        <div data-testid="userSalutation" class="w-full pr-xl">
          <div>{{ t('user.loginStatus.salutation') }}</div>
          <p class="w-full overflow-hidden font-bold overflow-ellipsis">
            {{ userContext.userData?.name1 ?? 'error loading user data' }}
          </p>
        </div>
      </div>
    </LoginStatus>
  </div>
</template>
<script setup lang="ts">
import { LoginStatus } from '@/complib';
import UserIcon from '@/components/user/userIcon/userIcon.vue';
import { useUserContext } from '~/stores/useUserContext';
defineEmits<{
  (e: 'close'): void;
}>();
const { t } = useTrans();
const userContext = useUserContext();
</script>
<style scoped lang="postcss">
.border-bot {
  @apply relative;
  &::after {
    content: '';
    @apply border-b-[1px] absolute bottom-0 border-solid border-minilu-base-lighter h-[1px] ml-md  w-[calc(100%-60px)];
  }
}
</style>
