<template>
  <div class="w-full h-full">
    <template v-if="dialog == EContactView.CONTACT">
      <DialogInline
        :views="contactViews"
        @handle-closing-from-inline="handleClosingWidgetFromInlineDialog"
      >
        <template #view_1>
          <div
            class="flex-1 overflow-y-auto text-sm p-sm md:p-md bg-vdv-bg-full sm:text-md"
          >
            <div
              class="flex items-start justify-end w-full pb-md gap-md pt-sm md:pt-0"
            >
              <img
                src="@/assets/images/minilu/minilu.svg"
                class="overflow-auto -ml-sm md:-ml-md"
                loading="lazy"
              />
              <div class="whitespace-nowrap">
                <b class="block">{{ t('globalWidget.serviceTime') }}</b>
                <div class="table pt-sm">
                  <div class="table-row">
                    <div class="table-cell leading-7 pr-md">
                      {{ t('globalWidget.monday') }}
                    </div>
                    <div class="table-cell leading-7 text-right">
                      8{{ t('globalWidget.timeSeparationSymbol') }}00 - 18{{
                        t('globalWidget.timeSeparationSymbol')
                      }}00 {{ t('globalWidget.time') }}
                    </div>
                  </div>
                  <div class="table-row">
                    <div class="table-cell leading-7 pr-md">
                      {{ t('globalWidget.tuesday') }}
                    </div>
                    <div class="table-cell leading-7 text-right">
                      8{{ t('globalWidget.timeSeparationSymbol') }}00 - 18{{
                        t('globalWidget.timeSeparationSymbol')
                      }}00 {{ t('globalWidget.time') }}
                    </div>
                  </div>
                  <div class="table-row">
                    <div class="table-cell leading-7 pr-md">
                      {{ t('globalWidget.wednesday') }}
                    </div>
                    <div class="table-cell leading-7 text-right">
                      8{{ t('globalWidget.timeSeparationSymbol') }}00 - 16{{
                        t('globalWidget.timeSeparationSymbol')
                      }}30 {{ t('globalWidget.time') }}
                    </div>
                  </div>
                  <div class="table-row">
                    <div class="table-cell leading-7 pr-md">
                      {{ t('globalWidget.thursday') }}
                    </div>
                    <div class="table-cell leading-7 text-right">
                      8{{ t('globalWidget.timeSeparationSymbol') }}00 - 18{{
                        t('globalWidget.timeSeparationSymbol')
                      }}00 {{ t('globalWidget.time') }}
                    </div>
                  </div>
                  <div class="table-row">
                    <div class="table-cell leading-7 pr-md">
                      {{ t('globalWidget.friday') }}
                    </div>
                    <div class="table-cell leading-7 text-right">
                      8{{ t('globalWidget.timeSeparationSymbol') }}00 - 15{{
                        t('globalWidget.timeSeparationSymbol')
                      }}00 {{ t('globalWidget.time') }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <ButtonBasic
              :full-width="true"
              :type="EButtonType.ROUTERLINK"
              :label="t('globalWidget.contactform')"
              class="text-lg mb-md h-9 font-pero"
              :btn-style="EButtonStyle.LIGHT"
              target="/kontakt"
              @click="emit('close')"
            />
            <p class="leading-7">
              <Trans
                keypath="globalWidget.faqText"
                tag="label"
                for="globalWidget.faqPage"
              >
                <LinkBasic
                  :label="t('globalWidget.faqPage')"
                  link="/faq"
                  target="_self"
                  class="font-bold text-minilu-base"
                  @click="emit('close')"
                />
              </Trans>
            </p>
          </div>
        </template>
      </DialogInline>
    </template>
    <template v-if="dialog == EContactView.BANNER">
      <DialogInline
        :views="bannerViews"
        :dialog="dialog"
        @handle-closing-from-inline="handleClosingWidgetFromInlineDialog"
      >
        <template #view_1>
          <WidgetNotificationBanner
            :banner-data="
              assignedBanners[0].banner as WidgetNotificationBannerData
            "
            class="overflow-y-auto"
          />
        </template>
      </DialogInline>
    </template>
  </div>
</template>

<script setup lang="ts">
import { EContactView } from '@/@types/contactview-type';
import { EButtonStyle, EButtonType } from '@/@types/basic-button';
import DialogInline from '~~/src/components/components/widgets/minilu/inline-dialog.vue';
import ButtonBasic from '@/components/components/core/basic-button/minilu/basic-button.vue';
import LinkBasic from '@/components/components/core/basicLink/basic-link.vue';
import Trans from '@/components/Trans.vue';
import { useAssignedBanners } from '~/composables/banner/useAssignedBanners';
import { BannerPositions } from '~/@types/bannerPositions';
import { BannerTypeHandle } from '~/components/banner/types';
import type { WidgetNotificationBannerData } from '~/components/banner/components/banners/widgetNotification/type';
import WidgetNotificationBanner from '~/components/banner/components/banners/widgetNotification/widgetNotificationBanner.vue';

const { t } = useTrans();

defineProps({
  dialog: {
    type: String as PropType<EContactView>,
    default: () => {
      [
        {
          id: '1',
          title: 'Kontakt',
        },
      ];
    },
  },
});

/** Emits */
const emit = defineEmits<{
  (e: 'close'): void;
  (e: 'handleClosing'): void;
}>();
const { assignedBanners } = await useAssignedBanners(
  BannerPositions.WIDGET_NOTIFICATION,
);

function handleClosingWidgetFromInlineDialog(): void {
  emit('handleClosing');
}

const contactViews = ref([
  {
    id: '1',
    title: t('globalWidget.contactLabel'),
  },
]);
const bannerViews = computed(() => {
  const banner = assignedBanners.value?.[0]
    ?.banner as WidgetNotificationBannerData;
  if (banner && banner.typeHandle === BannerTypeHandle.WIDGET_NOTIFICATION) {
    return [
      {
        id: '1',
        title: banner.headline,
        bgColor: ' !bg-status-danger-base',
      },
    ];
  }
  return [];
});
</script>
