<!-- eslint-disable vue/no-v-html -->
<template>
  <ContainerBasic
    container-type="FULL_WIDTH"
    class="h-max touch:md:h-[320px] lg:h-[320px]"
  >
    <ClientOnly>
      <div
        v-if="!isDesktopView"
        class="h-[170px] flex items-center bg-minilu-base bg-center bg-no-repeat bg-cover font-pero text-lg"
      >
        <img
          src="@/assets/images/minilu/newsletter-bg-mobile-top.svg"
          class="relative z-0 object-cover w-full h-full"
          alt="Newsletter Banner"
        />
        <img
          src="@/assets/images/minilu/newsletter-letter-mobile.svg"
          class="absolute z-10 pl-[75px] py-md"
          alt="Newsletter Banner Icon"
        />
      </div>
      <img
        v-if="isDesktopView"
        src="@/assets/images/minilu/newsletter-background.svg"
        class="h-[140] touch:md:h-[320px] lg:h-[320px] absolute z-[-10] min-w-full object-cover object-center bg-minilu-base"
        alt="Newsletter Banner"
      />
      <ContainerBasic
        ref="bannerNode"
        container-type="PAGE"
        class="relative flex h-max touch:md:h-full lg:h-full"
      >
        <img
          v-if="!isDesktopView"
          src="@/assets/images/minilu/newsletter-bg-mobile-bottom.svg"
          class="absolute z-0 object-cover h-full min-w-full overflow-hidden bg-minilu-base"
          alt="Newsletter Banner"
        />
        <div
          class="flex flex-col items-center h-full touch:md:justify-between touch:md:flex-row lg:justify-between lg:flex-row p-md"
        >
          <div
            class="relative z-20 flex flex-col touch:md:flex-row lg:flex-row"
          >
            <div class="lg:mb-sm">
              <img
                v-if="isDesktopView"
                src="@/assets/images/minilu/newsletter-letter.svg"
                class="lg:mr-lg touch:md:mr-lg w-[185px] pl-2xl touch:md:pl-0 touch:md:h-auto touch:md:mb-sm lg:pl-0 lg:h-auto lg:mb-sm"
                alt="Newsletter Banner Icon"
              />
            </div>
            <div class="self-center pr-0 md:pr-md">
              <h2
                class="text-2xl touch:md:text-3xl lg:text-3xl font-pero"
                v-html="t('newsletter.title')"
              ></h2>
              <ul class="mt-xs">
                <li
                  v-for="(value, key) in enumList"
                  :key="key"
                  class="flex align-middle mb-xs"
                >
                  <div
                    class="rounded-[16px] border-2 border-dotted w-[32px] h-[32px] mr-xs border-minilu-base"
                  >
                    <div
                      class="w-[24px] h-[24px] rounded-xl m-[2px] text-white flex place-items-center justify-center bg-minilu-base"
                    >
                      <FaIcon classes="text-white" :icon-class="iconClass" />
                    </div>
                  </div>
                  <span class="mt-[4px]">
                    {{ t(`newsletter.${value}`) }}
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <div class="w-[330px] mt-sm touch:md:mt-0 lg:mt-0 z-20">
            <FormKit
              id="paywallForm"
              type="form"
              class="max-w-md text-lg font-pero"
              spellcheck="false"
              :actions="false"
              :incomplete-message="false"
              :config="{ validationVisibility: 'blur' }"
              @submit="
                onNewsletterSubmit(
                  getActionUrlBySiteIdent(useSiteIdent()),
                  form,
                  'Default',
                )
              "
              @node="watchForm"
            >
              <FormKit
                type="text"
                name="EMAIL"
                :label="t('newsletter.yourEmail')"
                placeholder="max@mustermann.de"
                validation="required|email"
                validation-visibility="blur"
                input-class="w-full input rounded-corners"
                inner-class="relative pt-[5px]"
                label-class="text-md !leading-[19px] font-bold"
                :validation-messages="{
                  email: 'E-Mail-Adresse ist ungültig.',
                }"
              />

              <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
              <FormKit
                type="hidden"
                name="b_a8780ef3529534dbe1b892a2a_da0d21a119"
                value=""
              />

              <ButtonBasic
                classes="w-full my-sm px-0 font-pero text-lg"
                :label="t('newsletter.submit')"
                bg-color="#555555"
                :type="EButtonType.SUBMIT"
                :btn-style="EButtonStyle.CTA"
                :show-dialog="showDialog"
                :dialog-content="dialogContent"
                :full-width="true"
                @close-dialog="onDialogWindowClosed"
              />

              <p class="text-[14px] leading-5">
                <Trans keypath="newsletter.termsDescription" tag="label">
                  <a
                    :href="t('forms.agb.slug')"
                    rel="noopener"
                    target="_blank"
                    class="font-bold text-minilu-base"
                  >
                    {{ t('forms.agb') }}
                  </a>
                  <a
                    :href="t('forms.privacy.slug')"
                    rel="noopener"
                    target="_blank"
                    class="font-bold text-minilu-base"
                  >
                    {{ t('forms.privacy') }}
                  </a>
                </Trans>
              </p>
            </FormKit>
          </div>
        </div>
      </ContainerBasic>
    </ClientOnly>
  </ContainerBasic>
</template>

<script setup lang="ts">
import { EButtonStyle, EButtonType } from '@/@types/basic-button';
import ButtonBasic from '@/components/components/core/basic-button/minilu/basic-button.vue';
import { reset } from '@formkit/core';
import ContainerBasic from '@/components/components/container-basic.vue';
import FaIcon from '@/components/fa-icon.vue';
import Trans from '@/components/Trans.vue';
import {
  onNewsletterSubmit,
  getActionUrlBySiteIdent,
} from '~/composables/newsletterMailchimp';
import { FormKit } from '@formkit/vue';

const { t } = useTrans();
const bannerNode = ref(null);
const form = ref(null);
const iconClass = 'fas fa-check';

const enumList = ['listItem1', 'listItem2', 'listItem3'];

const showDialog = ref(false);
const dialogContent = ref({
  dialogHeadline: '',
  dialogText: '',
});
const dialogContentSuccess = {
  dialogHeadline: t('forms.dialogContent.successHeader'),
  dialogText: t('forms.dialogContent.successBody'),
};
const dialogContentError = {
  dialogHeadline: t('forms.dialogContent.errorHeader'),
  dialogText: '',
  dialogType: 'error',
};

const isDesktopView = useIsDesktopView();

function onDialogWindowClosed() {
  showDialog.value = false;
  reset('paywallForm');
}

if (import.meta.client) {
  (window as any).mcNewsletterDefault = {
    validateResponse: function (response: any) {
      dialogContent.value = {
        dialogHeadline: '',
        dialogText: '',
      };
      if (response.result === 'success') {
        dialogContent.value = dialogContentSuccess;
        if (response.msg.startsWith('Danke')) {
          //override the text when email already subscribed to newsletter
          dialogContent.value.dialogText = t(
            'forms.dialogContent.successThankYou',
          );
        }
        showDialog.value = true;
      } else {
        dialogContentError.dialogText =
          t('forms.dialogContent.errorBody') +
          '<br />' +
          '<span class="italic">' +
          response.msg +
          '</span>';
        dialogContent.value = dialogContentError;
        showDialog.value = true;
      }
    },
  };
}

function watchForm(n: any) {
  form.value = n;
}
</script>
