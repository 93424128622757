<!-- eslint-disable vue/v-on-event-hyphenation -->
<template>
  <client-only>
    <InfoBanner
      v-if="injected?.showInfobanner && infobannerStorage && shopIsEnabled"
      @closeInfobanner="hideInfobanner"
    />
  </client-only>
  <div
    class="top-0 left-0 right-0 bg-white z-[600] shrink-0"
    :class="{
      sticky: !isFixed,
      fixed: isFixed,
    }"
  >
    <div
      class="flex mx-auto max-w-screen-2xl place-content-between place-items-center flex-nowrap px-sm z-100 print:max-w-[720px] print:!px-0 gap-[50px] sm:gap-0 max-h-[72px]"
    >
      <div class="max-w-[165px] min-w-[100px] w-full">
        <NuxtLink to="/">
          <img
            v-if="languageCode === 'de'"
            src="~/assets/images/minilu/minilu-logo-de.svg"
            title="minilu logo"
            alt="minilu logo"
            data-testid="linkMiniluLogo"
            class="h-[45px] w-[185px] my-3"
          />

          <img
            v-if="languageCode === 'at'"
            src="~/assets/images/minilu/minilu-logo-at.svg"
            title="minilu logo"
            alt="minilu logo"
            class="h-[45px] w-[185px] my-3"
          />

          <img
            v-if="languageCode === 'nl'"
            src="~/assets/images/minilu/minilu-logo-nl.svg"
            title="minilu logo"
            alt="minilu logo"
            data-testid="linkMiniluLogo"
            class="h-[45px] w-[185px] my-3"
          />
        </NuxtLink>
      </div>

      <Searchbar v-if="shopIsEnabled" />

      <div
        v-if="shopIsEnabled"
        class="flex flex-row text-minilu-font print:hidden md:gap-[8px] touch:md:gap-0 desktop:lg:gap-0"
      >
        <HeaderLinks />
      </div>
    </div>
    <div
      class="relative bottom-0 left-0 right-0 h-2 bg-activesection-alpha print:h-[1px] print:max-w-[720px] mx-auto"
    />
  </div>
  <div
    class="hidden fixedPlaceholder shrink-0"
    :class="{
      '!block': isFixed,
    }"
  />
  <MainNav />
  <BannerPosition :position="BannerPositions.NOTIFICATION" />
  <BannerPosition :position="BannerPositions.SALES_BAR" />
</template>
<script setup lang="ts">
import MainNav from '@/components/components/navigation/minilu/main-nav.vue';
import InfoBanner from '../components/infoBanner/info-banner.vue';
import Searchbar from '../components/searchbar/searchbar.vue';
import HeaderLinks from './components/header-links.vue';
import { useStorage } from '@vueuse/core';
import { componentVisibilitySettings as componentVisibilitySettings_IK } from '@/injectionSymbols';
import BannerPosition from '~/components/banner/bannerPosition.vue';
import { BannerPositions } from '~/@types/bannerPositions';

const injected = inject(componentVisibilitySettings_IK);
const shopIsEnabled = useShopEnabled();
const infobannerStorage = useStorage('infobannerMinilu', true);

function hideInfobanner(showBanner: boolean) {
  infobannerStorage.value = showBanner;
}

const languageCode = useNuxtApp().$languageCode;
const { y } = useWindowScroll();

const isFixed = ref<boolean>();
watch(
  () => y.value,
  (newValue) => {
    isFixed.value = newValue > 200;
  },
);
</script>

<style lang="postcss" scoped>
.fixedPlaceholder {
  @apply w-full shrink-0;
  height: var(--sticky-header-height);
}
</style>
