<template>
  <div class="w-full bg-vdv-bg-base">
    <div
      class="grid max-w-full mx-auto touch:md:flex lg:flex md:max-w-screen-xl touch:md:justify-around lg:justify-around sm:grid sm:grid-cols-2 gap-sm px-md md:px-sm py-sm"
    >
      <div
        v-for="(benefit, index) in itemsByLanguage[$languageCode]"
        :key="index"
        class="flex flex-row items-center text-lg font-pero"
      >
        <FaIcon
          :icon="benefit.icon"
          classes="fa-lg mr-xs fa-fw text-minilu-base"
        />
        <p class="font-pero text-lg">{{ t(benefit.title) }}</p>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { IconData } from '~~/src/server/transformers/iconTransformer';
import FaIcon from '@/components/fa-icon.vue';

const { t } = useTrans();

interface footerItem {
  id: string;
  title: string;
  icon: IconData;
}

const footerItems: Record<string, footerItem> = {
  noCostShipment: {
    id: '1011',
    title: 'footer.usp.noCostShipment',
    icon: {
      iconSet: 'fal-icons',
      label: 'tooth',
    },
  },
  shipment24: {
    id: '1022',
    title: 'footer.usp.shipment24',
    icon: {
      iconSet: 'fal-icons',
      label: 'truck-fast',
    },
  },
  articles: {
    id: '1033',
    title: 'footer.usp.articles',
    icon: {
      iconSet: 'fal-icons',
      label: 'boxes',
    },
  },
  retour: {
    id: '1044',
    title: 'footer.usp.retour',
    icon: {
      iconSet: 'fal-icons',
      label: 'arrow-rotate-left',
    },
  },
  customers: {
    id: '1055',
    title: 'footer.usp.customers',
    icon: {
      iconSet: 'fal-icons',
      label: 'user-tie',
    },
  },
};

const itemsByLanguage: Record<string, footerItem[]> = {
  de: [
    footerItems.noCostShipment,
    footerItems.shipment24,
    footerItems.articles,
    footerItems.retour,
    footerItems.customers,
  ],
  at: [
    footerItems.noCostShipment,
    footerItems.articles,
    footerItems.shipment24,
    footerItems.retour,
    footerItems.customers,
  ],
  nl: [footerItems.articles, footerItems.retour, footerItems.customers],
};
</script>
